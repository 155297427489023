<template>
  <div class="NeedChangePass">
    <div class="content">
      <div class="form">
        <Art />
        <div class="formContent" v-if="user">
          <div class="formUserName">{{ user.name }}</div>
          <div class="formTitle">Su contraseña debe ser cambiada</div>
          <b-form-group>
            <mo-input v-model="password" label="Contraseña" input-type="password" />
          </b-form-group>
          <b-form-group>
            <mo-input v-model="repassword" label="Repetir Contraseña" input-type="password" />
          </b-form-group>
          <b-button type="button" :class="`login-form-submit pc-round ${password && repassword && 'ready'}`" @click="sendPassword">
            <span>INGRESAR</span>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {
      Art: () => import("@/common/art.vue"),
      MoInput: () => import("@/components/input/MoInput.vue"),
    },
    data: function() {
      return {
        password: "",
        repassword: "",
      };
    },
    methods: {
      pageExpire: function() {
        this.$router.push("/login");
      },
      sendPassword: function() {
        this.confirm = this.$Swal.fire({
          title: "Se cambiará su contraseña, ¿Desea continuar?",
          showCancelButton: true,
          confirmButtonText: "Si, cambiar",
          cancelButtonText: "No, cancelar",
        });

        this.confirm.then((result) => {
          if (result.isConfirmed) {
            this.saveNewPassword();
          }
        });
      },
      saveNewPassword: async function() {
        this.loading = this.$Swal.fire({
          text: "Enviando sus datos...",
          allowOutsideClick: false,
          didOpen: () => {
            this.$Swal.showLoading();
          },
        });

        try {
          const data = {
            password: this.password,
            repassword: this.repassword,
            ssid: this.user.SSID,
            _id: this.user._id,
          };

          this.res = await this.$api.post(`/auth/force_pass`, data);
          this.responseSaveNewPassword(this.res.data);
        } catch (error) {
          console.log(error);
          this.$Swal.fire({ icon: "error", title: "Error al cambiar la contraseña", text: error.message });
          return;
        }
      },
      responseSaveNewPassword: function({ success, message, token } = {}) {
        if (!success) {
          this.$Swal.fire({ icon: "error", title: "Error al cambiar la contraseña", text: message });
          return;
        }

        const user = this.$userData;

        this.$store.dispatch("user/setLogin", { token, user });

        this.success = this.$Swal.fire({ icon: "success", title: "Contraseña cambiada", text: message });
        this.success.then((result) => {
          this.$router.push("/login");
        });
      },
    },
    mounted: function() {
      if (!this.user) {
        this.pageExpire();
      }
    },
    computed: {
      user: function() {
        return this.$store.state.user.needPasswordChange;
      },
    },
  };
</script>

<style lang="scss">
  .NeedChangePass {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      width: 100%;
      max-width: 360px;
      padding: $mpadding;
    }

    .art {
      z-index: -1;
    }

    .formUserName {
      font-size: 150%;
      font-weight: bold;
      text-align: center;
      color: $primary_color;
    }

    .form {
      position: relative;
      height: 90vh;
      max-height: 650px;
      border-radius: 6px;
      box-shadow: 0 2px 5px #d8d8d8;
      padding: $mpadding;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    input {
      background-color: #fff;
    }

    .formContent {
      position: relative;
      margin: auto;
      z-index: 1;
    }

    .formTitle {
      font-size: 120%;
      text-align: center;
      margin: 0 0 $mpadding 0;
    }

    .login-form-submit {
      pointer-events: none;
    }

    .login-form-submit.ready {
      background-color: $primary_color;
      pointer-events: auto;
    }
  }
</style>
